/* eslint-disable indent */
import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Status, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { useDealershipListQuery } from '~/graphql/types'
import { useDebounce, usePaginationValues, usePermission } from '~/hooks'
import { theme } from '~/utils'

export const DealershipList: React.FC = () => {
  const navigate = useNavigate()
  const { page, size } = usePaginationValues()
  const [searchParams, setSearchParams] = useSearchParams()
  // const { dealerships, dealershipListIsLoading, getDealerships } = useDealership()
  const name = searchParams.get('name')
  const { data: dealerships, loading: dealershipListIsLoading } = useDealershipListQuery(
    {
      variables: {
        params: {
          pageSize: size,
          pageNumber: page,
          ...name && name?.length > 0 ? { name: name.trim() } : {},
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      },
      onCompleted(result) {
        setSearchParams(state => {
          state.set('items', String(result.dealershipList.meta.documentCounts))
          return state
        })

      },
    }
  )

  const [filterName, setFilterName] = useState(name || '')
  const debouncedFilterName = useDebounce(filterName, 1000)

  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  // não é aqui o bo do loop
  const handleFilters = (filters: { name: string }) => {
    setSearchParams(state => {
      if (filters.name) {
        state.set('name', filters.name)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }

  // não é aqui o bo do loop 
  useEffect(() => {
    handleFilters({ name: debouncedFilterName })
  }, [debouncedFilterName])

  // useEffect(() => {
  //   getDealerships({
  //     variables: {
  //       params: {
  //         pageSize: size,
  //         pageNumber: page,
  //         ...name && name?.length > 0 ? { name: name.trim() } : {},
  //         sort: {
  //           field: 'createdAt',
  //           order: -1
  //         }
  //       }
  //     },
  //     onCompleted(result) {
  //       setSearchParams(state => {
  //         state.set('items', String(result.dealershipList.meta.documentCounts))
  //         return state
  //       })

  //     },
  //   })
  // }, [searchParams])

  const canEditDealerships = usePermission(['dealership.updated', 'dealership.create'])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Estado',
        accessor: 'locationUf',
      },
      {
        Header: 'Desconto PF',
        accessor: 'maxDiscountPercentagePf',
      },
      {
        Header: 'Desconto PJ',
        accessor: 'maxDiscountPercentagePj',
      },
      {
        Header: 'Disponível',
        accessor: 'available',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {value ?
              <Status text='Disponível' background={theme.palette.success.light} color={theme.palette.success.main} />
              : <Status text='Indisponível' color={theme.palette.error.main} />
            }
          </>
        )

      },

      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            {canEditDealerships ? (
              <Link to={`/app/dealerships/update/${value}`}>
                <IconButton>
                  <IconArrowRight />
                </IconButton>
              </Link>
            ) : (
              <IconButton disabled={true}>
                <IconArrowRight />
              </IconButton>
            )}
          </>
        )
        ,
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => dealerships?.dealershipList.data || [], [dealerships])

  const defaultColumnHiddens = isLowerMd ? ['maxDiscountPercentagePf', 'maxDiscountPercentagePj'].concat(isLowerSm ? ['available'] : '') : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>,
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <div>

      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de concessionárias'
          description='Consulte todas as concessionárias cadastradas'
          breadcrumbLinks={{ currentLink: 'Listar concessionárias', links: [{ href: '/app/dealerships', label: 'Concessionárias' }] }}
          rightContent={<Button disabled={!canEditDealerships} onClick={() => navigate('/app/dealerships/create')} startIcon={<IconPlus />}>Cadastrar concessionárias</Button>}
        />
        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar concessionária por nome'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </>
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {tableMethods.selectedFlatRows.length !== 0 &&
                  (
                    <>
                      <Typography color='grey.600'>{tableMethods.selectedFlatRows.length} Selecionado(s)</Typography>
                      <Button sx={{ fontWeight: 600 }} size='small' startIcon={<IconTrash></IconTrash>} color='error'>Excluir</Button>
                    </>
                  )
                }
              </Box>
            )
          }
        />
        <TableApiV2
          isLoading={dealershipListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
