import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useNavigate, useParams, Navigate, Link } from 'react-router-dom'
import { ContentTitle } from '~/components/ContentTitle'
import { Input } from '~/components/Form/Input'
import { ConsortiumCreateDtoGraphql, EConsortiumStatus, useConsortiumCreateMutation, useConsortiumGetQuery, useConsortiumUpdateMutation } from '~/graphql/types'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { FullPageLoader } from '~/components/FullPageLoader'
import { clearString, theme } from '~/utils'
import { useCep, usePermission } from '~/hooks'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import * as yup from 'yup'
import { ConsortiumData } from '~/contexts/consortiums/types'
import { bankSchema, documentSchema, phoneSchema } from '~/utils/yupSchema'
import { IconCheck, IconSearch } from '@tabler/icons-react'
import { Radio } from '~/components/Form/Radio'
import { useBank } from '~/hooks/useBank'
import { bankAccountTypeOptions } from '~/utils/options'
import { DigitalAccountStatus } from '~/components'

export const ConsortiumCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedConsortium, setSelectedConsortium] = useState<ConsortiumData | null>(null)
  const canCreateAndEditConsortiums = usePermission(['consortiums.updated', 'consortiums.create'])
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const schema = yup
    .object({
      name: yup.string().required(),
      cnpj: documentSchema.required(),
      email: yup.string().email().required(),
      fantasyName: yup.string().required(),
      leaderEmail: yup.string().email().required(),
      leaderName: yup.string().required(),
      status: yup.string().required(),
      phone: phoneSchema,
      bank: bankSchema
        .when({
          is: () => {
            return Boolean(id && selectedConsortium)
          },
          then: (validationSchema) => validationSchema.nullable(),
          otherwise: (validationSchema) => validationSchema.required()
        }),

      legalRepresentative: yup.object().shape({
        name: yup.string().required(),
        document: documentSchema.required(),
      }).default(null)
        .when({
          is: () => {
            return Boolean(id && selectedConsortium)
          },
          then: (validationSchema) => validationSchema.nullable(),
          otherwise: (validationSchema) => validationSchema.required()
        }),

      address: yup.object().shape({
        city: yup.string().required(),
        country: yup.string().required(),
        neighborhood: yup.string().required(),
        number: yup.string().required(),
        state: yup.string().required(),
        street: yup.string().required(),
        zipcode: yup.string().required(),
        complement: yup.string(),
      }).required()
    })
    .required()

  const formMethods = useForm<ConsortiumCreateDtoGraphql>({ resolver: yupResolver(schema) })

  const { address, bank } = useWatch({ control: formMethods.control })
  const { fetchCep, isLoading: fetchCepIsLoading } = useCep({ showErrorMessage: true })
  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(bank?.code)

  const { refetch: refetchConsortiumGet, loading } = useConsortiumGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedConsortium(data.consortiumGet as ConsortiumData)
    },
    onError: () => {
      toast.error('Não foi possível carregar este consórcio.')
    }
  })

  useEffect(() => {
    if (id) {
      refetchConsortiumGet()
    }
    setSelectedConsortium(null)
  }, [id])

  const [createConsortium, { loading: createIsLoading }] = useConsortiumCreateMutation({
    onCompleted: () => {
      toast.success('Um novo consórcio foi cadastrado!')
      navigate('/app/consortiums')
    },
    onError: (error) => {
      if (error.message.includes('iugu')) {
        toast.error('Os dados bancários são inválidos.')
      } else {
        toast.error('Ocorreu um erro ao cadastrar um novo consórcio.')
      }
    }
  })
  const [updateConsortium, { loading: updateIsLoading }] = useConsortiumUpdateMutation({
    onCompleted: (data) => {
      setSelectedConsortium(null)
      toast.success(`O consórco ${data.consortiumUpdate.name} foi alterado!`)
      navigate(-1)
    },
    onError: () => {
      toast.error('Ocorreu um erro ao alterar o consórcio.')
    }
  })

  function onSubmit(data: ConsortiumCreateDtoGraphql) {

    if (id && selectedConsortium) {
      updateConsortium({
        variables: {
          params:
          {
            _id: id,
            email: data.email,
            fantasyName: data.fantasyName,
            leaderName: data.leaderName,
            leaderEmail: data.leaderEmail,
            name: data.name,
            status: data.status,
            cnpj: clearString(data.cnpj),
            phone: data.phone && clearString(data.phone),
            address: {
              ...data.address,
              zipcode: clearString(data.address.zipcode)
            }
          }
        }
      })
    } else {
      createConsortium({
        variables: {
          params: {
            ...data,
            cnpj: clearString(data.cnpj),
            phone: data.phone && clearString(data.phone),
            legalRepresentative: {
              name: data?.legalRepresentative?.name || '',
              document: clearString(data.legalRepresentative?.document || '')
            },
            address: {
              ...data.address,
              zipcode: clearString(data.address.zipcode)
            }
          }
        }
      })
    }
  }

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address?.zipcode)
      if (isSuccess) {
        formMethods.setValue('address.country', 'Brasil' || '')
        formMethods.setValue('address.state', data?.uf || '')
        formMethods.setValue('address.city', data?.localidade || '')
        formMethods.setValue('address.neighborhood', data?.bairro || '')
        formMethods.setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address])

  useEffect(() => {
    if (id && selectedConsortium) {
      formMethods.setValue('name', selectedConsortium?.name)
      formMethods.setValue('fantasyName', selectedConsortium?.fantasyName)
      formMethods.setValue('cnpj', selectedConsortium?.cnpj)
      formMethods.setValue('email', selectedConsortium?.email)
      formMethods.setValue('status', selectedConsortium?.status || EConsortiumStatus.inactive)
      formMethods.setValue('phone', selectedConsortium?.phone)
      formMethods.setValue('leaderName', selectedConsortium?.leaderName)
      formMethods.setValue('leaderEmail', selectedConsortium?.leaderEmail)
      formMethods.setValue('address.zipcode', selectedConsortium?.address.zipcode)
      formMethods.setValue('address.country', selectedConsortium?.address.country)
      formMethods.setValue('address.state', selectedConsortium?.address.state)
      formMethods.setValue('address.city', selectedConsortium?.address.city)
      formMethods.setValue('address.neighborhood', selectedConsortium?.address.neighborhood)
      formMethods.setValue('address.street', selectedConsortium?.address.street)
      formMethods.setValue('address.number', selectedConsortium?.address.number)
      formMethods.setValue('address.complement', selectedConsortium?.address.complement)
      formMethods.setValue('legalRepresentative.name', selectedConsortium.legalRepresentative?.name || '')
      formMethods.setValue('legalRepresentative.document', selectedConsortium.legalRepresentative?.document || '')

      if (selectedConsortium.bank) {
        formMethods.setValue('bank', selectedConsortium.bank)
      }
    } else {
      formMethods.reset()
    }
  }, [selectedConsortium, id])

  if (!canCreateAndEditConsortiums) {
    return <Navigate replace to='/app' />
  }

  if (id && loading) return <FullPageLoader />

  return (
    <Box sx={{ paddingBottom: '2rem' }}>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem', paddingBottom: '2rem' }}>
        <ContentTitle
          title={`${id && selectedConsortium ? `Alterar o consórcio ${selectedConsortium.name}` : 'Cadastrar consórcio'}`}
          description={`Complete os dados para ${id && selectedConsortium ? 'alterar' : 'cadastrar um'} Consórcio`}
          breadcrumbLinks={{ currentLink: `${id && selectedConsortium ? 'Alterar' : 'Cadastrar'} Consórcio`, links: [{ href: '/app/consortiums', label: 'Consórcios' }] }}
        />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box display='flex' flexDirection='column' gap='1rem'>
              <Typography fontWeight='1.05rem' variant='h6'>Dados básicos</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='name' label='Razão social' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input disabled={Boolean(id && selectedConsortium?._id)} mask='cnpj' name='cnpj' label='CNPJ' />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='fantasyName' label='Nome fantasia' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input mask='phone' name='phone' label='Telefone' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input name='email' label='E-mail' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Select options={[{ value: 'active', label: 'Ativo' }, { value: 'inactive', label: 'Inativo' }]} name='status' label='Status' />
                </Grid>
                <Grid sx={{ paddingTop: '0px !important' }} item xs={12} md={4} />
                <Grid item xs={12} sm={6} md={8}>
                  <Input name='leaderName' label='Nome do consorciado lider' />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Input name='leaderEmail' label='E-mail do consorciado lider' />
                </Grid>

              </Grid>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '1.4rem' }}>
                <Typography paddingTop='1rem' fontWeight='1.05rem' variant='h6'>Endereço</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input
                      icons={{ end: { element: fetchCepIsLoading ? <FullPageLoader /> : <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton> } }}
                      mask='cep'
                      name='address.zipcode'
                      label='CEP'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.country' label='País' />
                  </Grid>

                  <Grid sx={{ paddingTop: '0px !important' }} item xs={12} />
                  <Grid item xs={12} sm={6} md={4}>
                    <Select options={brazilStates} name='address.state' label='Estado' />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.city' label='Cidade' />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.neighborhood' label='Bairro' />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.street' label='Rua' />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.number' label='Número' />

                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input name='address.complement' label='Complemento' />

                  </Grid>
                </Grid>

                {/* {Boolean(!id && !selectedConsortium?._id) && ( */}
                <>
                  <Typography paddingTop='1rem' fontWeight='1.05rem' variant='h6'>Dados bancários</Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Select disabled={Boolean(id && selectedConsortium?._id)} label='Banco' name='bank.code' options={bankOptions} />
                    </Grid>
                    <Grid item xs={0} md={4} />
                    <Grid item xs={0} md={4} />

                    <Grid item xs={12} md={4}>
                      <Input disabled={Boolean(id && selectedConsortium?._id)} name='bank.agency' label={agencyLabel} customMask={agencyMask} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input customMask={accountMask} disabled={Boolean(id && selectedConsortium?._id)} name='bank.account' label={accountLabel} />
                    </Grid>

                    <Grid item xs={12}>
                      <Radio disabled={Boolean(id && selectedConsortium?._id)} row name='bank.type' options={bankAccountTypeOptions} />
                    </Grid>
                  </Grid>
                </>
                {/* )} */}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography paddingTop='1rem' fontWeight='1.05rem' variant='h6'>Representante legal</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <Input disabled={Boolean(id && selectedConsortium?._id)} name='legalRepresentative.name' label='Nome do representante legal' />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Input disabled={Boolean(id && selectedConsortium?._id)} mask='cpf' name='legalRepresentative.document' label='CPF do representante legal' />
                  </Grid>
                </Grid>
              </Box>

              {Boolean(id && selectedConsortium?._id) && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem', padding: '.6rem 0 1rem 0' }}>
                  <Typography color={theme.palette.black.light} fontWeight={500}>Status da conta digital:</Typography>
                  <DigitalAccountStatus status={selectedConsortium?.digitalAccount?.status || 'inactive'} />
                </Box>
              )}

              <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexFlow: isLowerSm ? 'column-reverse' : 'row',
                gap: isLowerSm ? '1rem' : '0'
              }}>

                <Link style={{ width: '100%', maxWidth: isLowerSm ? '100%' : '160px' }} to='/app/consortiums'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading || updateIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>
                <Button
                  startIcon={<IconCheck />}
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  fullWidth
                  disabled={createIsLoading || updateIsLoading}
                  type='submit'>
                  {id && selectedConsortium ? 'Alterar' : 'Cadastrar'} Consórcio
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Box>
  )
}
