import { EBankAccountType, EConsumerClassification, EContractFilterListPendencies, EContractStatus, EContractTariffClassification, ECustomerPersonType, ECustomerTension, EDealershipResolutionModality, EDiscountTableType, EInvoiceStatus, ELossReason, EMaritalStatus, EPowerPlantModality, EProposalFilterListPendencies, EProposalOrigin, EProposalPendenciesStatus, EProposalStatus, EStage, ETariffClassification, EUserType } from '~/graphql/types'
import { CONTRAC_INVOICE, PROPOSAL_ORIGIN, translateContractInvoice, translateContractStatus, translateLossReason, translateProposalContractOrigin, translateStageFunnel } from './translate'

export type Option = {
  label: string,
  value: string
  tooltipLabel?: string
}

export const defaultOption: Option = {
  value: '', label: ''
}

export const personTypeOptions: Option[] = [
  { value: ECustomerPersonType.pf, label: 'Pessoa Física' },
  { value: ECustomerPersonType.pj, label: 'Pessoa Jurídica' }
]

export const maritalStatusOptions: Option[] = [
  { value: EMaritalStatus.married, label: 'Casado(a)' },
  { value: EMaritalStatus.single, label: 'Solteiro(a)' },
  { value: EMaritalStatus.divorced, label: 'Divorciado(a)' },
  { value: EMaritalStatus.widowed, label: 'Viúvo(a)' }
]

export const tariffClassificationOptions: Option[] = [
  { value: ETariffClassification.A4, label: 'A4' },
  { value: ETariffClassification.B1, label: 'B1' },
  { value: ETariffClassification.B2, label: 'B2' },
  { value: ETariffClassification.B3, label: 'B3' }
]

export const customerTensionOptions: Option[] = [
  { value: ECustomerTension.monofasico, label: 'Monofásico' },
  { value: ECustomerTension.bifasico, label: 'Bifásico' },
  { value: ECustomerTension.trifasico, label: 'Trifásico' }
]

export const userTypeOptions: Option[] = [
  { value: EUserType.internal, label: 'Interno' },
  { value: EUserType.seller, label: 'Vendedor' },
]

export const getConsumerClassificationOptions = (tariffClassification: ETariffClassification | EContractTariffClassification): Option[] => {
  switch (tariffClassification) {
  case ETariffClassification.A4:
    return [
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  case ETariffClassification.B1:
    return [
      { value: EConsumerClassification.residencial, label: 'Residencial' }
    ]
  case ETariffClassification.B2:
    return [
      { value: EConsumerClassification.rural, label: 'Rural' }
    ]
  case ETariffClassification.B3:
    return [
      { value: EConsumerClassification.comercial, label: 'Comercial' },
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  default:
    return [defaultOption]
  }
}

export const proposalStatusOptions: Option[] = [
  { value: EProposalStatus.canceled, label: 'Cancelado' },
  { value: EProposalStatus.documentation, label: 'Documentação' },
  { value: EProposalStatus.finalized, label: 'Finalizado' },
  { value: EProposalStatus.formalization, label: 'Formalização' }
]

export const contractStatusOptions: Option[] = [
  { value: EContractStatus.cancellationRequested, label: translateContractStatus(EContractStatus.cancellationRequested) },
  { value: EContractStatus.deactivated, label: translateContractStatus(EContractStatus.deactivated) },
  { value: EContractStatus.inDeactivation, label: translateContractStatus(EContractStatus.inDeactivation) },
  { value: EContractStatus.readyToSend, label: translateContractStatus(EContractStatus.readyToSend) },
  { value: EContractStatus.reserved, label: translateContractStatus(EContractStatus.reserved) },
  { value: EContractStatus.sent, label: translateContractStatus(EContractStatus.sent) },
  { value: EContractStatus.signatures, label: translateContractStatus(EContractStatus.signatures) },
  { value: EContractStatus.active, label: translateContractStatus(EContractStatus.active) }
]

export const contractPendenciesOptions: Option[] = [
  { value: EContractFilterListPendencies.signTermAdhesion, label: 'Termo de adesão assinado' },
  { value: EContractFilterListPendencies.signTermManagement, label: 'Termo de gestão assinado' },
]

export const bankAccountTypeOptions: Option[] = [
  { value: EBankAccountType.cc, label: 'Conta corrente' },
  { value: EBankAccountType.cp, label: 'Poupança' }
]

export const invoiceTypeOptions: Option[] = [
  { value: 'C', label: 'Entrada' },
  { value: 'D', label: 'Saída' }
]

export const discountTableOptions: Option[] = [
  { value: EDiscountTableType.customerFloatFlag, label: 'Sim' },
  { value: EDiscountTableType.customerNotFloatFlag, label: 'Não' }
]

export const powerPlantModalityOptions: Option[] = [
  { value: EPowerPlantModality.gd1, label: 'GD I' },
  { value: EPowerPlantModality.gd2, label: 'GD II' },
  { value: EPowerPlantModality.gd3, label: 'GD III' },
]

export const dealershipModalityOptions: Option[] = [
  { value: EDealershipResolutionModality.conventional, label: 'Convencional' },
  { value: EDealershipResolutionModality.notConventional, label: 'Não convencional' }
]

export const ProposalPendenciesOptions: Option[] = [
  { value: EProposalFilterListPendencies.addressData, label: 'Endereço' },
  { value: EProposalFilterListPendencies.documentation, label: 'Documentação' },
  { value: EProposalFilterListPendencies.energyBill, label: 'Fatura de energia' },
  { value: EProposalFilterListPendencies.personalData, label: 'Dados pessoais' },
  // { value: EProposalFilterListPendencies.signTermAdhesion, label: 'Termo de gestão assinado' },
]

export const ProposalPendingDocumentsOptions: Option[] = [
  { value: EProposalPendenciesStatus.fullyResolved, label: 'Documentação completa recebida', tooltipLabel: 'O cliente enviou todos os documentos necessários à proposta' },
  { value: EProposalPendenciesStatus.partiallyResolved, label: 'Documentação parcial recebida', tooltipLabel: 'O cliente enviou alguns dos documentos necessários à proposta' },
]

export const ProposalContractOriginOptions: Option[] = [
  { value: EProposalOrigin.partnerLink, label: translateProposalContractOrigin(EProposalOrigin.partnerLink, PROPOSAL_ORIGIN) },
  { value: EProposalOrigin.partner, label: translateProposalContractOrigin(EProposalOrigin.partner, PROPOSAL_ORIGIN) },
  { value: EProposalOrigin.partnerApi, label: translateProposalContractOrigin(EProposalOrigin.partnerApi, PROPOSAL_ORIGIN) },
  { value: EProposalOrigin.website, label: translateProposalContractOrigin(EProposalOrigin.website, PROPOSAL_ORIGIN) },
  { value: EProposalOrigin.activeCampaign, label: translateProposalContractOrigin(EProposalOrigin.activeCampaign, PROPOSAL_ORIGIN) },
  { value: EProposalOrigin.internal, label: translateProposalContractOrigin(EProposalOrigin.internal, PROPOSAL_ORIGIN) },
]

export const contractInvoiceOptions: Option[] = [
  { value: EInvoiceStatus.canceled, label: translateContractInvoice(EInvoiceStatus.canceled, CONTRAC_INVOICE) },
  { value: EInvoiceStatus.late, label: translateContractInvoice(EInvoiceStatus.late, CONTRAC_INVOICE) },
  { value: EInvoiceStatus.paid, label: translateContractInvoice(EInvoiceStatus.paid, CONTRAC_INVOICE) },
  { value: EInvoiceStatus.pending, label: translateContractInvoice(EInvoiceStatus.pending, CONTRAC_INVOICE) },
  { value: EInvoiceStatus.expired, label: translateContractInvoice(EInvoiceStatus.expired, CONTRAC_INVOICE) }
  // { value: EInvoiceStatus.requested, label: translateContractInvoice(EInvoiceStatus.requested, CONTRAC_INVOICE) },
]

export const byInvoiceOptions: Option[] = [
  { value: 'false', label: '1ª via' },
  { value: 'true', label: '2ª via' },
]

export const lossReasonOptions: Option[] = [
  { value: ELossReason.beneficiaryDueToLowIncome, label: translateLossReason(ELossReason.beneficiaryDueToLowIncome) },
  { value: ELossReason.leadCreatedForTesting, label: translateLossReason(ELossReason.leadCreatedForTesting) },
  { value: ELossReason.duplicateLead, label: translateLossReason(ELossReason.duplicateLead) },
  { value: ELossReason.weDoNotServiceTheRegionPjOrPf, label: translateLossReason(ELossReason.weDoNotServiceTheRegionPjOrPf) },
  { value: ELossReason.weDoNotServicePfInTheRegion, label: translateLossReason(ELossReason.weDoNotServicePfInTheRegion) },
  { value: ELossReason.noContactReturned, label: translateLossReason(ELossReason.noContactReturned) },
  { value: ELossReason.noInterestLostInterest, label: translateLossReason(ELossReason.noInterestLostInterest) },
  { value: ELossReason.insufficientInvoiceAmount, label: translateLossReason(ELossReason.insufficientInvoiceAmount) },
  { value: ELossReason.other, label: translateLossReason(ELossReason.other) },
]

export const funnelStageOptions: Option[] = [
  { value: EStage.lead, label: translateStageFunnel(EStage.lead) },
  { value: EStage.opportunity, label: translateStageFunnel(EStage.opportunity) },
  { value: EStage.negotiating, label: translateStageFunnel(EStage.negotiating) },
  { value: EStage.negotiationCoolingDown, label: translateStageFunnel(EStage.negotiationCoolingDown) },
  { value: EStage.firstContactAttempt, label: translateStageFunnel(EStage.firstContactAttempt) },
  { value: EStage.secondContactAttempt, label: translateStageFunnel(EStage.secondContactAttempt) },
  { value: EStage.thirdContactAttempt, label: translateStageFunnel(EStage.thirdContactAttempt) },
  { value: EStage.contactReestablished, label: translateStageFunnel(EStage.contactReestablished) },
  { value: EStage.awaitingSignature, label: translateStageFunnel(EStage.awaitingSignature) },
  { value: EStage.legalAssembly, label: translateStageFunnel(EStage.legalAssembly) },
  { value: EStage.proposalContracted, label: translateStageFunnel(EStage.proposalContracted) },
  { value: EStage.lost, label: translateStageFunnel(EStage.lost) },
]

